







































import FileResource from '@/models/graphql/FileResource';
import { Component, Prop } from 'vue-property-decorator';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { deepGet } from '@/utils/ObjectHelpers';
import { BLink } from 'bootstrap-vue';
import SalesPackage from '@/models/graphql/SalesPackage';
import { BasicTypes } from '@/utils/types/BasicTypes';
import { namespace } from 'vuex-class';
import FormatHelper from '@/utils/helpers/FormatHelper';
import { mixins } from 'vue-class-component';
import BaseCardWidget from '@/components/cards/BaseCardWidget.vue';

const widgetDispatcherStore = namespace('WidgetDispatcherStore');
const localStorageCartStore = namespace('LocalStorageCartStore');

@Component({
  components: {
    BLink,
  },
})
/* eslint-disable no-underscore-dangle */
export default class PackageCard extends mixins(BreakpointWrapper, BaseCardWidget) {
  @Prop({ required: true })
  private uid!: string;

  @Prop({ required: true })
  private title!: string;

  @Prop({ required: true })
  private variantGroup!: string;

  @Prop({ required: false, default: null })
  private fileResource!: FileResource;

  @Prop({ required: true })
  private price!: number;

  @Prop({ required: true })
  private shortDescription!: string;

  @Prop({ default: null })
  readonly cardRoute!: string;

  @Prop({ default: null })
  readonly maximumQuantityPerExhibitor!: number;

  @Prop({ default: null })
  readonly _stock!: { uid: string; quantity: number };

  @Prop({ default: null })
  readonly _isPurchased!: number;

  @Prop({ default: false })
  readonly featured!: boolean;

  @Prop({ default: null })
  readonly childSalesPackages!: SalesPackage[];

  @widgetDispatcherStore.Getter
  private fetchMagicArgs!: Record<string, BasicTypes> ;

  @localStorageCartStore.Action
  private setCart!: (cartUid: string) => void;

  private packageStock = this._stock ? this._stock.quantity : 0;

  private get salePackage(): SalesPackage {
    return {
      uid: this.uid,
      title: this.title,
      fileResource: this.fileResource,
      price: this.price,
      maximumQuantityPerExhibitor: this.maximumQuantityPerExhibitor,
      _isPurchased: this._isPurchased,
    } as unknown as SalesPackage;
  }

  private get isCompanySet(): boolean {
    return !!(this.fetchMagicArgs.companyId);
  }

  private get packageTitle(): string {
    return this.childSalesPackages.length === 0 ? this.title : this.variantGroup;
  }

  mounted(): void {
    if (this.isCompanySet) {
      this.setCart(this.fetchMagicArgs.companyId as string);
    }
  }

  private get handleBaseUrl(): string | null {
    if (this.cardRoute) {
      let r = this.cardRoute;
      const matches = this.cardRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '').trim();
          const newValue = deepGet(this.$props, prop);
          if (newValue) {
            r = r.replaceAll(m, newValue);
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
        return r;
      }
      if (r[r.length - 1] === '/') {
        return `${r}${this.uid}`;
      }
      return `${r}/${this.uid}`;
    }
    return null;
  }

  private hasStock(): boolean {
    if (this.childSalesPackages.length > 0) {
      return this.childSalesPackages
        .some((variant) => (variant._stock ? (variant._stock.quantity > 0 || variant._stock.quantity === -1) : false));
    }
    return this.packageStock > 0 || this.packageStock === -1;
  }

  private getPackageStatus(): string {
    if (this.childSalesPackages.length === 0) {
      if (this.salePackage
        && this.salePackage.maximumQuantityPerExhibitor === 1
        && this.salePackage._isPurchased > 0) {
        return `${this.$t('sales.package-detail.already-purchased')}`;
      }
    }
    if (this.hasStock()) {
      return `${this.$t('sales.package-detail.in-stock')}`;
    }
    return `${this.$t('sales.package-detail.out-of-stock')}`;
  }

  private get formatCurrency(): string {
    if (this.childSalesPackages.length > 0) {
      const variantPrice = this.childSalesPackages
        .map((variant) => variant.price ?? 0);
      variantPrice.push(this.price);
      const lowPrice = FormatHelper.formatCurrency((Math.min(...variantPrice) || 0) / 100);
      const highPrice = FormatHelper.formatCurrency((Math.max(...variantPrice) || 0) / 100);
      return lowPrice === highPrice ? lowPrice : `${lowPrice} - ${highPrice}`;
    }
    return FormatHelper.formatCurrency((this.price || 0) / 100);
  }
}
